import { pathMap } from '@/lib/dataProviders/localizedPaths';
import { Locale } from '@/lib/enums/locale.enum';

const pathsWithSlug = ['ettevote', 'company', 'kompanija', 'isik', 'person', 'persona', 'blogi', 'blog'];
const isSluggedPage = (basePath: string) => pathsWithSlug.includes(basePath);

const getSluggedPagePath = (currentPath: string) => {
  const parts = currentPath.split('/').slice(1);
  // et
  if (pathsWithSlug.includes(parts[0])) {
    return `/${parts[0]}`;
  }
  // en, ru
  if (pathsWithSlug.includes(parts[1])) {
    return `/${parts[0]}/${parts[1]}`;
  }

  return currentPath;
};

export const getBasePath = (currentPath: string): string => {
  for (const key in pathMap) {
    if (Object.hasOwn(pathMap, key)) {
      const locales = pathMap[key];
      for (const locale in locales) {
        if (locales[locale] === currentPath) {
          return key;
        }
        if (locales[locale] === getSluggedPagePath(currentPath)) {
          return key;
        }
      }
    }
  }

  return '/';
};

export const getLocalizedPath = (basePath: string, currentPath: string, language: Locale): string => {
  const slug = isSluggedPage(basePath) ? parseSlug(currentPath) : null;
  return `${pathMap[basePath]?.[language]}${slug ? `/${slug}` : ''}` || '/';
};

const parseSlug = (currentPath: string) => {
  return currentPath.replace('/ru', '').replace('/en', '').split('/').slice(2, currentPath.length).join('/');
};
