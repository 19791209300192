'use client';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Block from '@/components/ui/Block';
import NonIdealState from '@/components/ui/NonIdealState';
import { notFoundImage } from '@/lib/helpers/hardcodedImages';
import { getLocalizedPath } from '@/components/helpers/localize';
import { Locale } from '@/lib/enums/locale.enum';

const NotFound: React.FC = () => {
  const { t, i18n } = useTranslation();
  const title = t('components.notFound.title');
  return (
    <Block>
      <h1 className="hidden">{title}</h1>
      <NonIdealState
        title={title}
        description={t('components.notFound.description')}
        imageUrl={notFoundImage()}
        buttons={[
          {
            title: t('components.notFound.back'),
            href: getLocalizedPath('frontpage', 'frontpage', i18n.language as Locale),
            theme: 'primary',
            size: 'large',
            iconRight: 'arrow03-right',
          },
        ]}
      />
    </Block>
  );
};

export default NotFound;
