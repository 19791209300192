import { Locale } from '@/lib/enums/locale.enum';

export const searchNoResultImage = () => {
  return `${process.env.NEXT_PUBLIC_IMAGES_HOST_URL}/Otsing.png`;
};

export const notFoundImage = () => {
  return `${process.env.NEXT_PUBLIC_IMAGES_HOST_URL}/404.png`;
};

export const getPaywallImageUrl = (name: string, lang: Locale) => `${process.env.NEXT_PUBLIC_IMAGES_HOST_URL}/${name}-${lang}.png`;
