export const pathMap: Record<string, Record<string, string>> = {
  'frontpage': {
    et: '/',
    en: '/en',
    ru: '/ru',
  },
  'about-us': {
    et: '/meist',
    en: '/en/about-us',
    ru: '/ru/o-nas',
  },
  'services-and-products': {
    et: '/teenused-ja-tooted',
    en: '/en/products-and-services',
    ru: '/ru/produkty-i-uslugi',
  },
  'background-information': {
    et: '/teenused-ja-tooted/taustainfo',
    en: '/en/products-and-services/background-information',
    ru: '/ru/produkty-i-uslugi/kreditnaya-informatsiya',
  },
  'debt-register': {
    et: '/teenused-ja-tooted/volaregister',
    en: '/en/products-and-services/debt-register',
    ru: '/ru/produkty-i-uslugi/dolgovoy-reyestr',
  },
  'market-review': {
    et: '/teenused-ja-tooted/konkurentsiraportid',
    en: '/en/products-and-services/market-review',
    ru: '/ru/produkty-i-uslugi/raporty',
  },
  'marketing-lists': {
    et: '/teenused-ja-tooted/turundusnimekirjad',
    en: '/en/products-and-services/marketing-lists',
    ru: '/ru/produkty-i-uslugi/marketingovyye-spiski',
  },
  'sales-agent': {
    et: '/teenused-ja-tooted/muugiagent',
    en: '/en/products-and-services/sales-agent',
    ru: '/ru/produkty-i-uslugi/agent',
  },
  'certificates': {
    et: '/teenused-ja-tooted/sertifikaadid',
    en: '/en/products-and-services/certificates',
    ru: '/ru/produkty-i-uslugi/sertifikaty',
  },
  'api': {
    et: '/teenused-ja-tooted/andmeliidesed',
    en: '/en/products-and-services/api',
    ru: '/ru/produkty-i-uslugi/api',
  },
  'packages': {
    et: '/hinnapaketid',
    en: '/en/packages',
    ru: '/ru/pakety',
  },
  'blog': {
    et: '/blogi',
    en: '/en/blog',
    ru: '/ru/blog',
  },
  'contact': {
    et: '/kontakt',
    en: '/en/contact',
    ru: '/ru/kontakti',
  },
  '404': {
    et: '/404',
    en: '/en/404',
    ru: '/ru/404',
  },
  'search': {
    et: '/otsing',
    en: '/en/search',
    ru: '/ru/poisk',
  },
  'company': {
    et: '/ettevote',
    en: '/en/company',
    ru: '/ru/kompanija',
  },
  'person': {
    et: '/isik',
    en: '/en/person',
    ru: '/ru/persona',
  },
  'my-account': {
    et: '/minu-konto',
    en: '/en/my-account',
    ru: '/ru/moi-akkaunt',
  },
  'terms': {
    et: '/kasutustingimused',
    en: '/en/terms-of-use',
    ru: '/ru/usloviya-ispolzovaniya',
  },
};
