'use client';

import * as React from 'react';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { IButtonProps } from '../Button';
import { IButtonIconProps } from '../ButtonIcon';
import { IButtonsPrimaryProps } from './Primary';
import { IButtonsSecondaryProps } from './Secondary';
import styles from './styles.module.scss';

export type ButtonChildren =
  | React.ReactElement<IButtonProps | IButtonIconProps | IButtonsPrimaryProps | IButtonsSecondaryProps>
  | boolean
  | undefined
  | null;

export interface IButtonsProps {
  /** Managed children */
  children: ButtonChildren | ButtonChildren[];
  /** size to use */
  size?: 'default' | 'small';
  /** Layout to use */
  layout?: 'default' | 'horizontal' | 'centered' | 'vertical' | 'vertical-wide';
  /** className to append */
  className?: string;
  /** Make it stick on mobile */
  stickyMobile?: boolean;
  /** Call to action text */
  title?: React.ReactNode;
  /** Style pass-through */
  style?: HTMLAttributes<HTMLUListElement>['style'];
}

/**
 * Displays Buttons
 */
const Buttons: React.FunctionComponent<IButtonsProps> = (props) => {
  if (React.Children.toArray(props.children).length === 0) {
    return null;
  }
  return (
    <ul
      className={classNames(
        styles['buttons'],
        {
          [styles['layout-default']]: props.layout === 'default' || !props.layout,
          [styles['layout-horizontal']]: props.layout === 'horizontal',
          [styles['layout-centered']]: props.layout === 'centered',
          [styles['layout-vertical']]: props.layout === 'vertical',
          [styles['layout-vertical-wide']]: props.layout === 'vertical-wide',
          [styles['size-small']]: props.size === 'small',

          [styles['sticky-mobile']]: props.stickyMobile,
        },
        props.className,
      )}
      style={props.style}
    >
      {props.title && <li className={styles['title']}>{props.title}</li>}
      {React.Children.map(props.children, (maybeChild: unknown) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const RenderChildren = (child: any, primary: boolean, secondary: boolean): any => {
          if (child === null || typeof child === 'string' || typeof child === 'number' || typeof child === 'boolean') {
            return child;
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (child.type === React.Fragment) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return React.Children.map(child.props.children, (innerChild) => {
              // eslint-disable-next-line
              return RenderChildren(innerChild, false, false);
            });
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!child.type || !child.type.displayName) {
            // check for lazy loaded components
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (typeof child.props !== 'undefined' && typeof child.type !== 'undefined') {
              return (
                <li
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                  key={child.key}
                  className={classNames({
                    [styles['primary']]: primary,
                    [styles['secondary']]: secondary,
                  })}
                >
                  {child}
                </li>
              );
            }
            return child;
          }

          if (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            child.type.displayName === 'Button' ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            child.type.displayName === 'ButtonIcon' ||
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            child.type.displayName === 'ButtonWithIcon'
          ) {
            return (
              <li
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                key={child.key}
                className={classNames({
                  [styles['primary']]: primary,
                  [styles['secondary']]: secondary,
                })}
              >
                {child}
              </li>
            );
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          } else if (child.type.displayName === 'ButtonsPrimary' || child.type.displayName === 'ButtonsSecondary') {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return React.Children.map(child.props.children, (sub) => {
              // eslint-disable-next-line
              return RenderChildren(sub, child.type.displayName === 'ButtonsPrimary', child.type.displayName === 'ButtonsSecondary');
            });
          } else {
            return child;
          }
        };

        // eslint-disable-next-line
        return RenderChildren(maybeChild, false, false);
      })}
    </ul>
  );
};

Buttons.displayName = 'Buttons';

export default Buttons;
